// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["uQyvZjoVU", "r4t2XX0Kq", "qpIdeHQsh", "Hv394yTXV", "XlP9GozM1", "GPcuktbLF", "M4qQJBQaE", "ENAQuCg2V", "hTeDCjezC", "WfLVJO228", "hHXTlWpB7", "ClGGA9hjD", "XsRsQyz6w", "RkOTEunvX"];

const serializationHash = "framer-is1ol"

const variantClassNames = {ClGGA9hjD: "framer-v-1ftoumj", ENAQuCg2V: "framer-v-1j6negl", GPcuktbLF: "framer-v-150zkh8", hHXTlWpB7: "framer-v-1cwixkl", hTeDCjezC: "framer-v-1jktjrg", Hv394yTXV: "framer-v-1q597lo", M4qQJBQaE: "framer-v-getwpw", qpIdeHQsh: "framer-v-161ygjy", r4t2XX0Kq: "framer-v-16kl61d", RkOTEunvX: "framer-v-9w1veb", uQyvZjoVU: "framer-v-ie4n8v", WfLVJO228: "framer-v-qzhe8r", XlP9GozM1: "framer-v-5ycrut", XsRsQyz6w: "framer-v-n0eavs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"2XL - 80px": "Hv394yTXV", "2XS - 16px": "WfLVJO228", "3XL - 96px": "qpIdeHQsh", "3XS - 12px": "hHXTlWpB7", "4XL - 120px": "r4t2XX0Kq", "4XS - 8px": "ClGGA9hjD", "5XL - 160px": "uQyvZjoVU", "5XS - 4px": "XsRsQyz6w", "6XS - 2px": "RkOTEunvX", "L - 48px": "GPcuktbLF", "M - 40px": "M4qQJBQaE", "S - 32px": "ENAQuCg2V", "XL - 64px": "XlP9GozM1", "XS - 24px": "hTeDCjezC"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "uQyvZjoVU"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "uQyvZjoVU", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "Hv394yTXV") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ie4n8v", className, classNames)} data-framer-name={"5XL - 160px"} layoutDependency={layoutDependency} layoutId={"uQyvZjoVU"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ClGGA9hjD: {"data-framer-name": "4XS - 8px"}, ENAQuCg2V: {"data-framer-name": "S - 32px"}, GPcuktbLF: {"data-framer-name": "L - 48px"}, hHXTlWpB7: {"data-framer-name": "3XS - 12px"}, hTeDCjezC: {"data-framer-name": "XS - 24px"}, Hv394yTXV: {"data-framer-name": "2XL - 80px"}, M4qQJBQaE: {"data-framer-name": "M - 40px"}, qpIdeHQsh: {"data-framer-name": "3XL - 96px"}, r4t2XX0Kq: {"data-framer-name": "4XL - 120px"}, RkOTEunvX: {"data-framer-name": "6XS - 2px"}, WfLVJO228: {"data-framer-name": "2XS - 16px"}, XlP9GozM1: {"data-framer-name": "XL - 64px"}, XsRsQyz6w: {"data-framer-name": "5XS - 4px"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-alignment": "center"}}><motion.br className={"trailing-break"}/></motion.p></React.Fragment>} className={"framer-12ubbtk"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"kLnDaIrp_"} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-is1ol.framer-1w2g2sd, .framer-is1ol .framer-1w2g2sd { display: block; }", ".framer-is1ol.framer-ie4n8v { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 160px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 160px; }", ".framer-is1ol .framer-12ubbtk { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-is1ol.framer-ie4n8v { gap: 0px; } .framer-is1ol.framer-ie4n8v > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-is1ol.framer-ie4n8v > :first-child { margin-top: 0px; } .framer-is1ol.framer-ie4n8v > :last-child { margin-bottom: 0px; } }", ".framer-is1ol.framer-v-16kl61d.framer-ie4n8v { height: 120px; width: 120px; }", ".framer-is1ol.framer-v-161ygjy.framer-ie4n8v { height: 96px; width: 96px; }", ".framer-is1ol.framer-v-1q597lo.framer-ie4n8v { height: 80px; width: 80px; }", ".framer-is1ol.framer-v-5ycrut.framer-ie4n8v { height: 64px; width: 64px; }", ".framer-is1ol.framer-v-150zkh8.framer-ie4n8v { height: 48px; width: 48px; }", ".framer-is1ol.framer-v-getwpw.framer-ie4n8v { height: 40px; width: 40px; }", ".framer-is1ol.framer-v-1j6negl.framer-ie4n8v { height: 32px; width: 32px; }", ".framer-is1ol.framer-v-1jktjrg.framer-ie4n8v { height: 24px; width: 24px; }", ".framer-is1ol.framer-v-qzhe8r.framer-ie4n8v { height: 16px; width: 16px; }", ".framer-is1ol.framer-v-1cwixkl.framer-ie4n8v { height: 12px; width: 12px; }", ".framer-is1ol.framer-v-1ftoumj.framer-ie4n8v { height: 8px; width: 8px; }", ".framer-is1ol.framer-v-n0eavs.framer-ie4n8v { height: 4px; width: 4px; }", ".framer-is1ol.framer-v-9w1veb.framer-ie4n8v { height: 2px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"r4t2XX0Kq":{"layout":["fixed","fixed"]},"qpIdeHQsh":{"layout":["fixed","fixed"]},"Hv394yTXV":{"layout":["fixed","fixed"]},"XlP9GozM1":{"layout":["fixed","fixed"]},"GPcuktbLF":{"layout":["fixed","fixed"]},"M4qQJBQaE":{"layout":["fixed","fixed"]},"ENAQuCg2V":{"layout":["fixed","fixed"]},"hTeDCjezC":{"layout":["fixed","fixed"]},"WfLVJO228":{"layout":["fixed","fixed"]},"hHXTlWpB7":{"layout":["fixed","fixed"]},"ClGGA9hjD":{"layout":["fixed","fixed"]},"XsRsQyz6w":{"layout":["fixed","fixed"]},"RkOTEunvX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerstesKSk1V: React.ComponentType<Props> = withCSS(Component, css, "framer-is1ol") as typeof Component;
export default FramerstesKSk1V;

FramerstesKSk1V.displayName = "Spacing Block";

FramerstesKSk1V.defaultProps = {height: 160, width: 160};

addPropertyControls(FramerstesKSk1V, {variant: {options: ["uQyvZjoVU", "r4t2XX0Kq", "qpIdeHQsh", "Hv394yTXV", "XlP9GozM1", "GPcuktbLF", "M4qQJBQaE", "ENAQuCg2V", "hTeDCjezC", "WfLVJO228", "hHXTlWpB7", "ClGGA9hjD", "XsRsQyz6w", "RkOTEunvX"], optionTitles: ["5XL - 160px", "4XL - 120px", "3XL - 96px", "2XL - 80px", "XL - 64px", "L - 48px", "M - 40px", "S - 32px", "XS - 24px", "2XS - 16px", "3XS - 12px", "4XS - 8px", "5XS - 4px", "6XS - 2px"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerstesKSk1V, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})